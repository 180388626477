// MainHeader

import { Button, FormControl, TextField } from "@mui/material";
import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import akLogo from "../../images/akLogo.png";
import cancel from "../../images/cancel.png";
import menu from "../../images/menu.png";
import next from "../../images/next.png";
import removeIcon from "../../images/cancel.png";
import init from "../helper/WindowToken";
import Constants from "./Constants";

import Lottie from "lottie-react-web";
import closeEyeImg from "../../images/closeEyeImg.png";
import eye from "../../images/eyeImg.png";
import notificationLoader from "../../images/notification_loader.json";
import profile from "../../images/user (3).png";
import user from "../../images/userBlack.png";
// import OtpInput from "react-otp-input";
import OTPInput, { ResendOTP } from "otp-input-react";
import scholarImage from "../../images/scholarship.png";
import userImage from "../../images/account.png";
import secure from "../../images/secure.png";
import name from "../../images/name.png";
import mail from "../../images/mail.png";
import messageIcon from "../../images/message-box.png";
import callerIcon from "../../images/caller-icon.png";
import facebookIcon from "../../images/facebook-icon.png";
import instagramIcon from "../../images/instagram-icon.png";
import youtubeIcon from "../../images/youtube-icon.png";

export default class Header extends Component {
  state = {
    showRegister: false,
    showLogin: false,
    overlay: false,
    courses: [
      { label: "9th Standard", value: "9th Standard" },
      { label: "10th Standard", value: "10th Standard" },
      { label: "CA Foundation", value: "CA Foundation" },
      { label: "CA Inter", value: "CA Inter" },
      { label: "Ca Final", value: "Ca Final" },
    ],
    course: "",
    books: "",
    selectedType: false,
    dropDown: false,
    dropDown2: false,
    showNotif: false,
    notiMessage: "",
    btnLoading: false,
    firstName: "",
    email: "",
    mobileNo: "",
    password: "",
    confirmPassword: "",
    userDetails: {
      name: "",
      email: "",
      phone_number: 0,
    },
    showError: false,
    max: false,
    passError: false,
    emailLogin: "",
    passwordLogin: "",
    openEye: false,
    closeEye: true,
    message: "",
    courseCategory: [],
    testCat: [],
    facetofaceCat: [],
    selectCat: false,
    headerType: "",
    categoryId: "",
    categoryType: "",
    levels: [],
    streams: [],
    selectedCourseId: "",
    categoryname: "",
    catName: "",
    attempts: [],
    userInfo: {},
    SideNavBar: false,
    firstScreen: true,
    secondScreen: false,
    loader: false,
    scrolled: false,
    hash: "",
    otp: "",
    userId: "",

    minutes: 1,
    seconds: 0,
    toggleCourses: false,
    toggleBooks: false,
    category_id: "",
    level_id: "",
    stream_id: "",
  };
  componentDidMount() {
    this.getUserInfo();
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        this.setState({ userInfo: res.data }, () => {});
      });
    }
  };
  updateUserDetails = (e) => {
    e.preventDefault();
    if (this.state.showCorrectEmail && this.state.showCorrectName) {
      this.setState({ loading: true });
      const payload = {
        FIRST_NAME: this.state.firstName,
        EMAIL: this.state.email,
        user_id: this.state.userId,
      };
      axios.post(Constants.postUrls.updateUser, payload).then((res) => {
        if (res.data.status === "success") {
          this.setState({
            notifMsg: res.data.message,
            showRegister: false,
            loading: false,
          });
          this.getUserInfo();
        }
      });
    } else {
      this.setState({
        notifMsg: "Enter user name and email correctly.",

        loading: false,
      });
    }
  };

  getCategory = (type) => {
    this.setState({
      headerType: type,
    });

    if (type === "course") {
      axios.get(Constants.getUrls.productsBycatLevels).then((resp) => {
        if (resp.data.length > 0) {
          this.setState(
            {
              courseCategory: resp.data,
              categoryId: resp.data[0].Id,
            },
            () => {
              this.getLevels(this.state.categoryId);
            }
          );
        }
      });
    } else if (type === "facetoface") {
      axios.get(Constants.getUrls.faceCat).then((resFaceCat) => {
        if (resFaceCat.data.data.length > 0) {
          this.setState(
            {
              courseCategory: resFaceCat.data.data,
              categoryId: resFaceCat.data.data[0].id,
            },
            () => {
              this.getFacetoFaceLevels(this.state.categoryId);
            }
          );
        }
      });
    } else if (type === "testseries") {
      axios.get(Constants.getUrls.productsTestseries).then((resTest) => {
        if (resTest.data.length > 0) {
          this.setState({
            courseCategory: resTest.data,
          });
        }
      });
    } else if (type === "books") {
      axios.get(Constants.getUrls.getLevelStreamForBooks).then((resp) => {
        console.log(resp);
        if (resp.data.length > 0) {
          this.setState(
            {
              courseCategory: resp.data,
              categoryId: resp.data[0].Id,
            },
            () => {
              this.getLevels(this.state.categoryId);
            }
          );
        }
      });
    }
  };

  getLevels = (id) => {
    const newId = this.state.courseCategory.find(
      (courseId) => courseId.ID || courseId.id === id
    );
    console.log(newId);
    this.setState({
      levels: newId.level,
      streams: newId.level ? newId.level[0].stream : [],
      selectedCourseId: newId,
      category_id: newId.ID,
      level_id: newId.level[0].id,
      stream_id: newId.level[0].stream[0].id,
    },() => {
      console.log(this.state.category_id,this.state.level_id,this.state.stream_id);
    });
  };
  getFacetoFaceLevels = (id) => {
    axios
      .get(
        Constants.getUrls.getLevelsFacetoFace +
          "?category_id=" +
          id +
          "&type=COURSE"
      )
      .then((res) => {
        this.setState(
          {
            levels: res.data,
            // levelsFacetoFace: res.data,
            // showStreamsF: true,
            // hideLevels: false,
          },
          () => {
            this.getFacetoFaceStreams(this.state.levels[0].id);
          }
        );
      });
  };
  getFacetoFaceStreams = (id) => {
    axios
      .get(
        Constants.getUrls.getStreamsFacetoFace +
          "?level_id=" +
          id +
          "&type=COURSE"
      )
      .then((res) => {
        this.setState({
          streams: res.data,
        });
      });
  };

  showRegister = () => {
    this.setState({
      showRegister: true,
      overlay: true,
    });
  };

  hideRegister = () => {
    this.setState({
      showRegister: false,
      overlay: false,
    });
  };

  showLogin = () => {
    this.setState({
      showLogin: true,
      overlay: true,
    });
  };

  hideLogin = () => {
    this.setState({
      showLogin: false,
      overlay: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleRegister = (e) => {
    if (
      this.state.showCorrectName === true &&
      this.state.showCorrectEmail === true &&
      this.state.mobilevalidate === true &&
      this.state.password === this.state.confirmPassword
    ) {
      e.preventDefault();
      this.setState({
        showNotif: true,
        notiMessage: "Creating account...",
        btnLoading: true,
        loader: true,
      });
      const payLoad = {
        FIRST_NAME: this.state.firstName,
        EMAIL: this.state.email,
        mobile: this.state.mobileNo,
        PASSWORD: this.state.password,
        password_confirmation: this.state.confirmPassword,
        course: this.state.course,
      };
      axios.post(Constants.postUrls.register, payLoad).then((res) => {
        if (res.data.status === "success") {
          this.setState({
            showRegister: false,
            showLogin: true,
            userDetails: res.data.data,
            btnLoading: false,
            message: res.data.message,
            loader: false,
          });
          var frm = document.getElementById("registerForm");
          frm.reset();
        } else {
          this.setState({
            showError: true,
            message: res.data.message,
            btnLoading: false,
            showNotif: false,
            loader: false,
          });
          var frm = document.getElementById("registerForm");
          setTimeout(() => {
            this.setState({ showError: false });

            // frm.reset();
          }, 2000);
        }
      });
    } else {
      this.setState({
        showError: true,
        message: "Invalid Name,Email,Mobile number or Password.",
        btnLoading: false,
        showNotif: false,
        loader: false,
      });
    }
  };

  handleLogin = (e) => {
    this.setState({ loader: true });
    e.preventDefault();
    this.setState({
      showNotif: true,
      notiMessage: "Signing in...",
      btnLoading: true,
      showError: false,
    });
    const payload = {
      email: this.state.emailLogin,
      password: this.state.passwordLogin,
      status: "main",
    };
    axios.post(Constants.postUrls.login, payload).then((res) => {
      if (res.data.status === "success") {
        localStorage.setItem("token", res.data.token);
        if (init() === "success") {
          this.setState({
            userDetails: res.data.data,
            message: res.data.credential,
            showLogin: false,
            loader: false,
          });
          var frm = document.getElementById("loginForm");
          frm.reset();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      } else {
        this.setState({
          showError: true,
          message: res.data.credential,
          btnLoading: false,
          showNotif: false,
          loader: false,
        });
        var frm = document.getElementById("loginForm");
        setTimeout(() => {
          this.setState({ showError: false });

          frm.reset();
        }, 2000);
      }
    });
  };
  loginUser = (e) => {
    e.preventDefault();
    this.setState({ loaderLogin: true });
    const payload = {
      mobile: this.state.mobileNo,
      name: this.state.firstName,
      hash_key: this.state.hash,
    };
    axios
      .post(Constants.postUrls.sendOTP, payload)
      .then((res) => {

        if (res.data.status === "success") {
          this.setState({
            OTPModal: true,
            // showLogin: false,
            otpSent: "Otp Sent",
            from: "Login",
            loaderLogin: false,
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
      });
  };
  // verifyOTP = (e) => {
  //   if (this.state.otp.length > 6) {
  //     e.preventDefault();
  //     this.setState({
  //       showError: true,
  //       message: "Enter Valid OTP of 6 digits",
  //     });
  //   } else {
  //     e.preventDefault();
  //     this.setState({ loader: true });
  //     const Type = this.state.from;

  //     let ApiUrl =
  //       Type === "Login"
  //         ? Constants.postUrls.verifyOTP
  //         : Constants.postUrls.verifyRegisterOTP;
  //     let payload;

  //     payload = {
  //       mobile: this.state.mobileNo,
  //       otp: this.state.otp,
  //     };

  //     axios.post(ApiUrl, payload).then((res) => {
  //       if (res.data.status === "success") {
  //         localStorage.setItem("token", res.data.token);
  //         localStorage.setItem("user_data", res.data.user.ID);
  //         if (init() === "success") {
  //           if (res.data.user.FIRST_NAME === "") {
  //             this.setState({
  //               showRegister: false,
  //               showLogin: false,
  //               userId: res.data.user.ID,
  //               showUserDetails: true,
  //               loader: false,
  //             });
  //             // this.updateUserDetails();
  //           } else {
  //             this.setState({
  //               userDetails: res.data.user,
  //               message: res.data.message,
  //               showLogin: false,
  //               OTPModal: false,
  //               loader: false,
  //               userId: res.data.user.ID,
  //             });
  //             this.getUserInfo();
  //           }

  //           // var frm = document.getElementById("loginForm");
  //           // frm.reset();
  //           // window.location.href = "/";
  //         } else {
  //           alert("Something went wrong");
  //         }
  //       } else {
  //         this.setState({
  //           showError: true,
  //           message: res.data.message,
  //           btnLoading: false,
  //           showNotif: false,
  //           loader: false,
  //         });
  //         var frm = document.getElementById("loginForm");
  //         setTimeout(() => {
  //           this.setState({ showError: false });

  //           frm.reset();
  //         }, 2000);
  //       }
  //     });
  //   }
  // };

  verifyOTP = (e) => {
    if (this.state.otp.length > 6) {
      e.preventDefault();
      this.setState({
        showError: true,
        message: "Enter Valid OTP of 6 digits",
      });
    } else {
      e.preventDefault();
      this.setState({ loader: true });
      const Type = this.state.from;
  
      let ApiUrl =
        Type === "Login"
          ? Constants.postUrls.verifyOTP
          : Constants.postUrls.verifyRegisterOTP;
      let payload = {
        mobile: this.state.mobileNo,
        otp: this.state.otp,
      };
  
      axios.post(ApiUrl, payload).then((res) => {
        if (res.data.status === "success") {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user_data", res.data.user.ID);
          if (init() === "success") {
            if (res.data.user.FIRST_NAME === "") {
              this.setState({
                showRegister: false,
                showLogin: false,
                userId: res.data.user.ID,
                showUserDetails: true,
                loader: false,
              });
            } else {
              this.setState({
                userDetails: res.data.user,
                message: res.data.message,
                showLogin: false,
                OTPModal: false,
                loader: false,
                userId: res.data.user.ID,
              });
              this.getUserInfo();
            }
          } else {
            alert("Something went wrong");
          }
        } else {
          this.setState({
            showError: true,
            message: res.data.message,
            btnLoading: false,
            showNotif: false,
            loader: false,
          });
          var frm = document.getElementById("loginForm");
          setTimeout(() => {
            this.setState({ showError: false });
            frm.reset();
          }, 2000);
        }
      }).catch((error) => {
        if (error.response && error.response.status === 500) {
          this.setState({
            notifMsg: "Something went wrong while login",
            loader: false,
          });
        } else {
          this.setState({
            showError: true,
            message: "An error occurred. Please try again.",
            loader: false,
          });
        }
      });
    }
  };
  

  logOut = (e) => {
    localStorage.clear();
    window.location.href = "/";
  };
  validateName = (text) => {
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      this.setState({
        firstName: text,
        showCorrectName: false,
        showNameError: true,
      });
      return false;
    } else {
      this.setState({
        firstName: text,
        showCorrectName: true,
        showNameError: false,
      });
    }
  };
  validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      this.setState({
        email: text,
        showCorrectEmail: false,
        showEmailError: true,
      });
      return false;
    } else {
      this.setState({
        email: text,
        showCorrectEmail: true,
        showEmailError: false,
      });
    }
  };
  validateMobile(text) {
    const reg = /^[0]?[56789]\d{9}$/;
    if (reg.test(text) === false) {
      this.setState({
        mobilevalidate: false,
        mobileNo: text,
        showmobileError: true,
      });
      return false;
    } else {
      this.setState({
        mobilevalidate: true,
        mobileNo: text,
        message: "",
        showmobileError: false,
      });
      return true;
    }
  }
  validateOTP(text) {
    const reg = /^[0]?[789]\d{5}$/;
    if (reg.test(text) === false) {
      this.setState({
        otpValidate: false,
        otp: text,
        showOtpError: true,
      });
      return false;
    } else {
      this.setState({
        otpValidate: true,
        otp: text,
        message: "",
        showOtpError: false,
      });
      return true;
    }
  }
  render() {
    const { minutes, seconds } = this.state;
    const renderButton = (buttonProps) => {
      return (
        <button
          {...buttonProps}
          style={{
            border: "none",
            backgroundColor: "#fff",
            marginBottom: "10px",
            fontWeight: 500,
          }}
        >
          {this.state.sentOTP ? "OTP Sent" : "Resend OTP"}
        </button>
      );
    };
    const renderTime = (remainingTime) => {
      return <span>00 : {remainingTime} s</span>;
    };
    return (
      <div className="NewHeader">
        <div className="headerTopHead Web">
          <p style={{ fontSize: "16px", fontWeight: "400" }}>
            To Know more about the courses call us at -{" "}
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              9356624677{" "}
            </span>{" "}
            <span
              style={{
                fontSize: "20px",
                fontWeight: "700",
                marginLeft: "25px",
                marginRight: "25px",
              }}
            >
              |{" "}
            </span>{" "}
            For Any kind of Technical Support Please call us on -{" "}
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              8766578508
            </span>
          </p>
        </div>
        <div className="headerTopHead Responsive">
          <p style={{ fontSize: "16px", marginTop: "5px" }}>
            To Know more please call
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              9356624677{" "}
            </span>
          </p>
        </div>

        <section>
          <div
            className={
              this.state.showRegister ? "_show sideBar" : "_hide sideBar"
            }
          >
            <div style={{ position: "relative" }}>
              <img
                src={removeIcon}
                alt=""
                className="removeIcon"
                onClick={this.hideRegister}
              />
              <img src={scholarImage} alt="" className="scholarImage" />
            </div>

            <form
              onSubmit={(e) => {
                if (this.state.showUserDetails) {
                  this.updateUserDetails(e);
                } else if (this.state.OTPModal) {
                  this.verifyOTP(e);
                } else {
                  this.loginUser(e);
                }
              }}
              id="registerForm"
            >
              {this.state.showError && (
                <div className="notifWrapReg _showNotif">
                  <p style={{ color: "#fff" }}>{this.state.message}</p>
                </div>
              )}
              <section className="sectionReg">
                <div className="bottomSpace2">
                  <h2 className="signUp">SIGN UP</h2>
                  <p
                    onClick={() => {
                      this.setState({ showRegister: false, showLogin: true });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    or{" "}
                    <span style={{ color: "#3a519d" }}>login your account</span>{" "}
                  </p>
                  <p className="underLine"></p>
                </div>
                <div className="bottomSpace2" style={{ position: "relative" }}>
                  <input
                    name="mobileNo"
                    className="name inputTag"
                    type="number"
                    required
                    placeholder="Phone number"
                    onChange={(e) => {
                      this.setState({ mobileNo: e.target.value }, () => {
                        this.validateMobile(this.state.mobileNo);
                        if (this.state.mobileNo.length > 10) {
                          this.setState({ max: true });
                        } else {
                          this.setState({ max: false });
                        }
                      });
                    }}
                  />
                  <img src={userImage} className="username" />
                  {this.state.showmobileError &&
                  this.state.mobileNo.length > 0 ? (
                    <p style={{ color: "red", fontSize: 10 }}>
                      Enter valid Mobile number
                    </p>
                  ) : null}
                  {this.state.max ? (
                    <div>
                      <span style={{ color: "red" }}>
                        Max Allowed Characters : 10
                      </span>
                    </div>
                  ) : null}
                </div>
                {this.state.OTPModal && (
                  <div
                    className="bottomSpaceBtnLogin"
                    style={{ position: "relative" }}
                  >
                    <input
                      name="otp"
                      className="name inputTag"
                      type="number"
                      required
                      placeholder="OTP"
                      onChange={(e) => {
                        this.setState({ otp: e.target.value });
                      }}
                      style={{ position: "relative", marginBottom: 10 }}
                    />
                    <img src={secure} className="username" />
                    {this.state.otp.length > 6 ? (
                      <p style={{ color: "red", fontSize: 10 }}>
                        Enter valid OTP
                      </p>
                    ) : null}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: " space-between",
                      }}
                    >
                      <p>
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                      <button
                        onClick={(e) =>
                          this.setState(
                            {
                              sentOTP: true,
                              loader: false,
                              loaderLogin: false,
                            },
                            () => {
                              this.loginUser(e);
                            }
                          )
                        }
                        style={{
                          border: "none",
                          backgroundColor: "#fff",
                          marginBottom: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {this.state.sentOTP ? "OTP Sent" : "Resend OTP"}
                      </button>
                    </div>
                  </div>
                )}
                {this.state.showUserDetails && (
                  <>
                    <div
                      className="bottomSpace"
                      style={{ position: "relative" }}
                    >
                      <input
                        name="firstName"
                        className="name inputTag"
                        type="text"
                        required
                        placeholder="Name"
                        onChange={(e) => {
                          this.setState({ firstName: e.target.value }, () => {
                            this.validateName(this.state.firstName);
                          });
                        }}
                      />
                      <img src={name} className="username" />

                      {this.state.showNameError &&
                      this.state.firstName.length > 0 ? (
                        <p style={{ color: "red", fontSize: 10 }}>
                          Enter valid name
                        </p>
                      ) : null}
                    </div>
                    <div
                      className="bottomSpace"
                      style={{ position: "relative" }}
                    >
                      <input
                        name="email"
                        className="name inputTag"
                        type="email"
                        required
                        placeholder="Email"
                        onChange={(e) => {
                          this.setState({ email: e.target.value }, () => {
                            this.validateEmail(this.state.email);
                          });
                        }}
                      />
                      <img src={mail} className="username" />

                      {this.state.showEmailError &&
                      this.state.email.length > 0 ? (
                        <p style={{ color: "red", fontSize: 10 }}>
                          Enter valid e-mail address
                        </p>
                      ) : null}
                    </div>
                  </>
                )}
                <div className="">
                  <Button
                    type="submit"
                    variant="contained"
                    className="signinBtn"
                    style={{
                      fontSize: "17px",
                      padding: "23px",
                      borderRadius: "0px",
                    }}
                  >
                    {this.state.loading ||
                    this.state.loader ||
                    this.state.loaderLogin ? (
                      <Lottie
                        options={{
                          animationData: notificationLoader,
                        }}
                        style={{
                          width: "30px",
                          height: "30px",
                          margin: 0,
                        }}
                      />
                    ) : this.state.showUserDetails ? (
                      " UPDATE DETAILS"
                    ) : this.state.OTPModal ? (
                      "VERIFY OTP"
                    ) : (
                      "SIGN UP"
                    )}
                  </Button>
                </div>
              </section>
            </form>
          </div>
          {this.state.showRegister && (
            <div
              className="overlay"
              onClick={() => {
                this.setState({
                  showRegister: false,
                });
              }}
            ></div>
          )}
        </section>

        {/* OTP Login */}
        <section>
          <div
            className={this.state.showLogin ? "_show sideBar" : "_hide sideBar"}
          >
            <div style={{ position: "relative" }}>
              <img
                src={removeIcon}
                alt=""
                className="removeIcon"
                onClick={this.hideLogin}
              />
              <img
                src={scholarImage}
                alt=""
                className="scholarImage"
                onClick={this.hideLogin}
              />
            </div>

            <form
              onSubmit={(e) => {
                if (this.state.OTPModal) {
                  this.verifyOTP(e);
                } else {
                  this.loginUser(e);
                }
              }}
              id="loginForm"
            >
              {this.state.showError && (
                <div className="notifWrap _showNotif">
                  <p style={{ color: "#fff" }}>{this.state.message} </p>
                </div>
              )}
              <section className="sectionLog">
                <div className="bottomSpace2">
                  <h2 className="signUp">Login</h2>
                  <p
                    onClick={() => {
                      this.setState({ showRegister: true, showLogin: false });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    or{" "}
                    <span style={{ color: "#3a519d" }}>create an account</span>{" "}
                  </p>
                  <p className="underLine"></p>
                </div>

                <div className="bottomSpace2" style={{ position: "relative" }}>
                  <input
                    name="mobileNo"
                    className="name inputTag"
                    type="number"
                    required
                    placeholder="Phone number"
                    onChange={(e) => {
                      this.setState({ mobileNo: e.target.value }, () => {
                        this.validateMobile(this.state.mobileNo);
                        if (this.state.mobileNo.length > 10) {
                          this.setState({ max: true });
                        } else {
                          this.setState({ max: false });
                        }
                      });
                    }}
                  />
                  <img src={userImage} className="username" />

                  {this.state.showmobileError &&
                  this.state.mobileNo.length > 0 ? (
                    <p style={{ color: "red", fontSize: 10 }}>
                      Enter valid Mobile number
                    </p>
                  ) : null}
                  {this.state.max ? (
                    <div>
                      <span style={{ color: "red" }}>
                        Max Allowed Characters : 10
                      </span>
                    </div>
                  ) : null}
                </div>
                {this.state.OTPModal && (
                  <div
                    className="bottomSpaceBtnLogin"
                    style={{ position: "relative" }}
                  >
                    <input
                      name="otp"
                      className="name inputTag"
                      type="number"
                      required
                      placeholder="OTP"
                      onChange={(e) => {
                        this.setState({ otp: e.target.value }, () => {
                          // this.validateOTP(this.state.otp);
                        });
                      }}
                      style={{ position: "relative", marginBottom: 10 }}
                    />
                    {/* <img src={secure} className="username" /> */}
                    {this.state.otp.length > 6 ? (
                      <p style={{ color: "red", fontSize: 10 }}>
                        Enter valid OTP
                      </p>
                    ) : null}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: " space-between",
                      }}
                    >
                      <p>
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                      <button
                        onClick={(e) =>
                          this.setState(
                            {
                              sentOTP: true,
                              loader: false,
                              loaderLogin: false,
                            },
                            () => {
                              this.loginUser(e);
                            }
                          )
                        }
                        style={{
                          border: "none",
                          backgroundColor: "#fff",
                          marginBottom: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {this.state.sentOTP ? "OTP Sent" : "Resend OTP"}
                      </button>
                    </div>
                  </div>
                )}

                <div className="">
                  <Button
                    type="submit"
                    variant="contained"
                    className="signinBtn"
                    style={{
                      fontSize: "17px",
                      padding: "23px",
                      borderRadius: "0px",
                    }}
                  >
                    {this.state.loader || this.state.loaderLogin ? (
                      <Lottie
                        options={{
                          animationData: notificationLoader,
                        }}
                        style={{
                          width: "30px",
                          height: "30px",
                          margin: 0,
                        }}
                      />
                    ) : this.state.OTPModal ? (
                      "VERIFY OTP"
                    ) : (
                      " SIGN IN"
                    )}
                  </Button>
                </div>
              </section>
            </form>
          </div>
          {this.state.showLogin && (
            <div
              className="overlay"
              onClick={() => {
                alert();
                this.setState({
                  showLogin: false,
                });
              }}
            ></div>
          )}
        </section>

        {window.innerWidth <= 1180 ? (
          <div className="navHeader">
            {" "}
            <div style={{ display: "flex" }}>
              <Link to="/">
                <img src={akLogo} alt="" className="logoAK" />
              </Link>
              <Link to="/">
                <div
                  className="logo-text"
                  style={{
                    position: "relative",
                    top: "8px",
                    left: "10px",
                  }}
                >
                  <h1
                    style={{
                      margin: 0,
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#003399",
                    }}
                  >
                    AK CLASSES
                  </h1>
                  <h5
                    style={{
                      margin: 0,
                      fontFamily: "Caveat, cursive",
                      textAlign: "right",
                    }}
                  >
                    Educator at Home
                  </h5>
                </div>
              </Link>
            </div>
            {this.props.From === "SubjectsResp" ? (
              <div
                className=""
                onClick={() => {
                  this.setState({
                    SideNavBar: !this.state.SideNavBar,
                    firstScreen: true,
                    secondScreen: false,
                  });
                }}
              >
                <img
                  src={menu}
                  alt="img"
                  className="menuImg"
                  height={20}
                  width={20}
                />
              </div>
            ) : (
              <div
                className=" menu"
                onClick={() => {
                  this.setState({
                    SideNavBar: !this.state.SideNavBar,
                    firstScreen: true,
                    secondScreen: false,
                  });
                }}
              >
                Menu{" "}
                <img
                  src={menu}
                  alt="img"
                  className="menuImg"
                  height={20}
                  width={20}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            className="mainWrap "
            onMouseLeave={(e) => {
              this.setState({
                dropDown: false,
              });
            }}
          >
            <div className="secondWrap" style={{ padding: "0rem 3rem" }}>
              <Link to="/">
                <img
                  src={akLogo}
                  alt=""
                  className="logo"
                  style={{
                    width: "60px",
                    height: "60px",
                    objectFit: "cover",
                    position: "relative",
                    top: "9px",
                    left: "9px",
                  }}
                />
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div
                  className="logo-text"
                  style={{
                    position: "relative",
                    top: "20px",
                    left: "16px",
                  }}
                >
                  <h1
                    style={{
                      margin: 0,
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#003399",
                    }}
                  >
                    AK CLASSES
                  </h1>
                  <h5
                    style={{
                      margin: 0,
                      fontFamily: "Caveat, cursive",
                      color: "black",
                      textAlign: "right",
                    }}
                  >
                    Educator at Home
                  </h5>
                </div>
              </Link>
              <p
                onMouseOver={(e) => {
                  this.setState(
                    {
                      selectedType: "course",
                      dropDown: true,
                    },
                    () => {
                      this.getCategory(this.state.selectedType);
                    }
                  );
                }}
                onMouseLeave={() => {
                  this.setState({ selectedType: "", dropDown: true });
                }}
                style={{
                  padding: "5px 14px",
                  marginLeft: "50px",
                  backgroundColor: "#003399",
                  color: "white",
                  width:"150px"
                }}
                className={
                  this.state.selectedType === "course"
                    ? "selectedTypes Menudropdown"
                    : "types"
                }
              >
                <span style={{ display: "inline-flex", marginTop: "6px" }}>
                  Video Lectures
                </span>
                <span
                  className="arrowHexHeader"
                  style={{ top: 1, fontSize: "15px", left: "6px" }}
                >
                  <i class="fa-solid fa-caret-down"></i>
                </span>
                {/* <span className="arrowHexHeader">&#129171;</span> */}
              </p>
              {/* <p
                className={
                  this.state.selectedType === "facetoface"
                    ? "selectedTypes"
                    : "types"
                }
                style={{ padding: "5px 14px" }}
                onMouseOver={(e) => {
                  this.setState(
                    {
                      selectedType: "facetoface",
                      dropDown: true,
                    },
                    () => {
                      this.getCategory(this.state.selectedType);
                    }
                  );
                }}
                onMouseLeave={() => {
                  this.setState({ selectedType: "face", dropDown: true });
                }}
              >
                {" "}
                <span style={{ display: "inline-flex", marginTop: "6px" }}>
                  Face to Face & Live Stream{" "}
                </span>
                <span
                  className="arrowHexHeader"
                  style={{ top: 1, fontSize: "15px", left: "6px" }}
                >
                  <i class="fa-solid fa-caret-down"></i>
                </span>
              </p> */}
              {/* <p
                onClick={() => {
                  // alert();
                  window.location.href = "/facetoface_details/:id/:id/:id";
                }}
                className={
                  this.state.selectedType === "facetoface_details"
                    ? "selectedTypes"
                    : "types"
                }
                style={{ padding: "5px 14px" }}
                onMouseOver={(e) => {
                  this.setState(
                    {
                      selectedType: "facetoface_details",
                      dropDown: false,
                    },
                    () => {
                      // this.getCategory(this.state.selectedType);
                    }
                  );
                }}
                onMouseLeave={() => {
                  this.setState({ selectedType: "", dropDown: false });
                }}
              >
                {" "}
                <span style={{ display: "inline-flex", marginTop: "8px" }}>
                  Face to Face & Live Batch
                </span>{" "}
                <span
                  className="arrowHexHeader"
                  style={{ top: 1, fontSize: "15px", left: "6px" }}
                >
                  <i class="fa-solid fa-caret-down"></i>
                </span>
              </p> */}
              <p
                onMouseOver={(e) => {
                  this.setState(
                    {
                      selectedType: "books",
                      dropDown: true,
                    },
                    () => {
                      this.getCategory(this.state.selectedType);
                    }
                  );
                }}
                onMouseLeave={() => {
                  this.setState({ selectedType: "books", dropDown: true });
                }}
                style={{
                  padding: "5px 14px",
                  marginLeft: "15px",
                  backgroundColor: "#003399",
                  color: "white",
                }}
                className={
                  this.state.selectedType === "books"
                    ? "selectedTypes Menudropdown"
                    : "types"
                }
              >
                <span style={{ display: "inline-flex", marginTop: "6px" }}>
                Books & Test Series
                </span>
                <span
                  className="arrowHexHeader"
                  style={{ top: 1, fontSize: "15px", left: "6px" }}
                >
                  <i class="fa-solid fa-caret-down"></i>
                </span>
                {/* <span className="arrowHexHeader">&#129171;</span> */}
              </p>
            </div>
            {init() === "success" ? (
              <>
                <div className="secondWrap" onClick={this.showLogin}></div>
                <Link to="/dashboard/orders">
                  <div className="register" style={{ textAlign: "center" }}>
                    <img
                      src={profile}
                      alt="profile"
                      width="16px"
                      height="16px"
                      style={{ marginTop: "-4px" }}
                    />
                    <span style={{ marginRight: "10px" }}>
                      {" "}
                      Hi {this.state.userInfo?.FIRST_NAME}
                    </span>
                  </div>
                </Link>
              </>
            ) : (
              <>
                {/* <div className="secondWrap">
                  
                  <p className="register" onClick={this.showLogin}>
                    Login{" "}
                  </p>
                  <p className="register" onClick={this.showRegister}>
                    Register{" "}
                  </p>
                </div> */}
                <div className="login-container">
                  <div className="divider"></div>
                  <span onClick={this.showLogin}>Log In</span>
                  <button
                    onClick={() => {
                      this.setState({ showRegister: true, showLogin: false });
                    }}
                  >
                    Register
                  </button>
                </div>
              </>
            )}

            {this.state.dropDown ? (
              <div
                className={
                  window.innerWidth === 1396
                    ? "row dropdownall1396 Menudropdown-contents"
                    : "row dropdownall Menudropdown-contents"
                }
                style={{
                  marginLeft:
                    this.state.selectedType === "course"
                      ? "-45px"
                      : this.state.selectedType === "facetoface"
                      ? "117px"
                      : this.state.selectedType === "testseries"
                      ? "365px"
                      : this.state.selectedType === "studymaterial"
                      ? "497px"
                      : this.state.selectedType === ""
                      ? "-45px"
                      : this.state.selectedType === "face"
                      ? "117px"
                      : this.state.selectedType === "test"
                      ? "365px"
                      : this.state.selectedType === "books"
                      ? "120px"
                      : null,
                }}
              >
                <div
                  className="col-md-12"
                  style={{
                    fontFamily: "'Raleway', sans-serif",
                    fontSize: "16px",
                    marginLeft: "-30px",
                  }}
                >
                  <div
                    className="col-md-4 categoryArea"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    {this.state.streams.map((cat) => (
                      <div className={this.state.selectCat ? "cat1396" : "cat"}>
                        {this.state.headerType === "facetoface" ? (
                          <Link
                            style={{ textDecoretion: "none" }}
                            to={
                              "/facetoface_details/" +
                              this.state.categoryId +
                              "/" +
                              this.state.levels[0].id +
                              "/" +
                              cat.id
                            }
                          >
                            {" "}
                            <div
                              className="col-md-12 colorDiv"
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className="col-md-12"
                                style={{ marginLeft: "-10px" }}
                              >
                                <h5
                                  style={{
                                    position: "absolute",
                                    top: "6px",
                                    width: "100%",
                                  }}
                                >
                                  {cat.name}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        ) : this.state.headerType === "course" ? (
                          <Link to={"/subjects/" + cat.id}>
                            <div
                              className="col-md-12 colorDiv"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-md-12">
                                <h5
                                  style={{
                                    position: "absolute",
                                    top: "6px",
                                  }}
                                >
                                  {cat.name}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        ) : this.state.headerType === "books" ? (
                          <Link to={"/booksnew?" + "categoryId=" + btoa(this.state.category_id) + "&levelId=" + btoa(this.state.level_id) + "&streamId=" + btoa(this.state.stream_id) + "&subject_filter_id="}>
                            <div
                              className="col-md-12 colorDiv"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-md-12">
                                <h5
                                  style={{
                                    position: "absolute",
                                    top: "6px",
                                  }}
                                >
                                  {cat.name}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <Link to={"/subjects/" + cat.id}>
                            <div
                              className="col-md-12 colorDiv"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-md-12">
                                <h5
                                  style={{
                                    position: "absolute",
                                    top: "6px",
                                  }}
                                >
                                  {cat.name}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            
          </div>
        )}
        {this.state.SideNavBar ? (
          <div className="RespHeader slideFromRight">
            <div className="resp-logo-header">
              <div className="ak-logo">
                <img src={akLogo} alt="AK Logo" />

                <div className="logo-title">
                  <h1>AK CLASSES</h1>
                  <h5>Educator at Home</h5>
                </div>
              </div>

              <div
                className="cancel-icon"
                onClick={() => {
                  this.setState({ SideNavBar: false });
                }}
              >
                x
              </div>
            </div>
            {/* <img
              src={cancel}
              alt=""
              className="cancelIcon slidefromLeft"
              onClick={() => {
                this.setState({ SideNavBar: false });
              }}
            />
            <h4
              className="backBtn"
              onClick={() => {
                this.setState({ firstScreen: true, secondScreen: false });
              }}
            >
              Back
            </h4> */}
            {this.state.firstScreen && (
              <div className="secondDivNavbar">
                {init() === "success" ? (
                  <a
                    href="/dashboard/orders"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <p>
                      {" "}
                      <img
                        src={user}
                        alt="profile"
                        width="16px"
                        height="16px"
                        style={{ marginTop: "-4px" }}
                      />{" "}
                      Hi, {this.state.userInfo?.FIRST_NAME}
                    </p>
                  </a>
                ) : (
                  <>
                    {/* <p
                      onClick={() => {
                        this.setState({ showLogin: true, SideNavBar: false });
                      }}
                    >
                      Login
                    </p> */}
                    {/* <p
                      onClick={() => {
                        this.setState({
                          showRegister: true,
                          SideNavBar: false,
                        });
                      }}
                    >
                      Register
                    </p> */}
                  </>
                )}
                <p
                  onClick={() => {
                    this.setState({ SideNavBar: false });
                  }}
                >
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "#646464" }}
                  >
                    Home
                  </a>
                </p>
                <div>
                  <p
                    onClick={() => {
                      this.setState(
                        {
                          // secondScreen: true,
                          // firstScreen: false,
                          toggleCourses: !this.state.toggleCourses,
                          showVideoLectures: true,
                          showBooks: false,
                          toggleBooks: false,
                          selectedType: "course",
                        },
                        () => {
                          this.getCategory(this.state.selectedType);
                        }
                      );
                    }}
                    style={{ color: this.state.toggleCourses ? "#0668E2" : "" }}
                  >
                    Video Lectures
                    <img
                      src={next}
                      alt=""
                      className="nextIcon slidefromLeft"
                      onClick={() => {
                        this.setState({ secondScreen: true });
                      }}
                    />
                  </p>
                  {/* <p
                    onClick={() => {
                      this.setState(
                        {
                          // secondScreen: true,
                          // firstScreen: false,
                          toggleCourses: !this.state.toggleCourses,
                          showVideoLectures: true,
                          selectedType: "books",
                        },
                        () => {
                          this.getCategory(this.state.selectedType);
                        }
                      );
                    }}
                    style={{ color: this.state.toggleCourses ? "#0668E2" : "" }}
                  >
                    Books
                    <img
                      src={next}
                      alt=""
                      className="nextIcon slidefromLeft"
                      // onClick={() => {
                      //   this.setState({ secondScreen: true });
                      // }}
                    />
                  </p> */}
                  {this.state.toggleCourses && (
                    <>
                      {this.state.showVideoLectures ? (
                        <div>
                          {this.state.streams.map((cat) => (
                            <div
                              className="secondDivNavbar"
                              onClick={() => {
                                this.setState({
                                  SideNavBar: false,
                                });
                              }}
                            >
                              {this.state.headerType === "facetoface" ? (
                                <Link
                                  style={{ textDecoretion: "none" }}
                                  to={
                                    "/facetoface_details/" +
                                    this.state.categoryId +
                                    "/" +
                                    this.state.levels[0].id +
                                    "/" +
                                    cat.id
                                  }
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    <p style={{ color: "#000" }}>{cat.name}</p>
                                  </div>
                                </Link>
                              ) : this.state.headerType === "course" ? (
                                <Link
                                  to={"/subjects/" + cat.id}
                                  style={{ textDecoretion: "none" }}
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    <p style={{ color: "#000" }}>{cat.name}</p>
                                  </div>
                                </Link>
                              ) : this.state.headerType === "books" ? (
                                <Link
                                  to={"/booksnew/" + cat.id}
                                  style={{ textDecoretion: "none" }}
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    <p style={{ color: "#000" }}>{cat.name}</p>
                                  </div>
                                </Link>
                              ) : (
                                <Link
                                  to={"/subjects/" + cat.id}
                                  style={{ textDecoretion: "none" }}
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    <p style={{ color: "#000" }}>{cat.name}</p>
                                  </div>
                                </Link>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                <p
                  onClick={() => {
                    this.setState(
                      {
                        secondScreen: true,
                        firstScreen: false,
                        showVideoLectures: true,
                        selectedType: "facetoface",
                      },
                      () => {
                        this.getCategory(this.state.selectedType);
                      }
                    );
                  }}
                >
                  Face to Face & Live Stream{" "}
                  <img
                    src={next}
                    alt=""
                    className="nextIcon slidefromLeft"
                    onClick={() => {
                      this.setState({ SideNavBar: false });
                    }}
                  />
                </p>
                {/* <Link to="/testseries" style={{ color: "black" }}>
                  <p
                    onClick={() => {
                      this.setState(
                        {
                          firstScreen: false,

                          selectedType: "testseries",
                          SideNavBar: false,
                        }
                        // () => {
                        //   this.getCategory(this.state.selectedType);
                        // }
                      );
                    }}
                  >
                    Face to Face & Live Batch
                    <img src={next} alt="" className="nextIcon slidefromLeft" />
                  </p>
                </Link> */}
                {/* <Link to="/booksnew" style={{ color: "black" }}> */}
                  <p
                    onClick={() => {
                      this.setState(
                        {
                          // secondScreen: true,
                          // firstScreen: false,
                          toggleBooks: !this.state.toggleBooks,
                          toggleCourses: false,
                          showBooks: true,
                          showVideoLectures: false,
                          selectedType: "books",
                        },
                        () => {
                          this.getCategory(this.state.selectedType);
                        }
                      );
                    }}
                    style={{ color: this.state.toggleBooks ? "#0668E2" : "" }}
                  >
                    Books & Test Series
                    <img src={next} alt="" className="nextIcon slidefromLeft" />
                  </p>
                  {this.state.toggleBooks && (
                    <>
                      {this.state.showBooks ? (
                        <div>
                          {this.state.streams.map((cat) => (
                            <div
                              className="secondDivNavbar"
                              onClick={() => {
                                this.setState({
                                  SideNavBar: false,
                                });
                              }}
                            >
                              {this.state.headerType === "facetoface" ? (
                                <Link
                                  style={{ textDecoretion: "none" }}
                                  to={
                                    "/facetoface_details/" +
                                    this.state.categoryId +
                                    "/" +
                                    this.state.levels[0].id +
                                    "/" +
                                    cat.id
                                  }
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    <p style={{ color: "#000" }}>{cat.name}</p>
                                  </div>
                                </Link>
                              ) : this.state.headerType === "course" ? (
                                <Link
                                  to={"/subjects/" + cat.id}
                                  style={{ textDecoretion: "none" }}
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    <p style={{ color: "#000" }}>{cat.name}</p>
                                  </div>
                                </Link>
                              ) : this.state.headerType === "books" ? (
                                <Link to={"/booksnew?" + "categoryId=" + btoa(this.state.category_id) + "&levelId=" + btoa(this.state.level_id) + "&streamId=" + btoa(this.state.stream_id) + "&subject_filter_id="}>
                                  <div style={{ cursor: "pointer" }}>
                                    <p style={{ color: "#000" }}>{cat.name}</p>
                                  </div>
                                </Link>
                              ) : (
                                <Link
                                  to={"/subjects/" + cat.id}
                                  style={{ textDecoretion: "none" }}
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    <p style={{ color: "#000" }}>{cat.name}</p>
                                  </div>
                                </Link>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </>
                  )}
                {/* </Link> */}

                <div className="ak-info">
                  <div className="info">
                    <img src={messageIcon} alt="message icon" />
                    <span>-bvlhelpdesk@gmail.com</span>
                  </div>
                  <div className="info">
                    <img src={callerIcon} alt="phone icon" />
                    <span>+91 9356624677</span>
                  </div>
                </div>

                <div className="ak-login-container">
                  <div className="ak-social-icons">
                    <a href="#instagram">
                      <img src={instagramIcon} alt="instagram" />
                    </a>
                    <a href="#facebook">
                      <img src={facebookIcon} alt="facebook" />
                    </a>
                    <a href="#youtube">
                      <img src={youtubeIcon} alt="youtube" />
                    </a>
                  </div>

                  <div className="ak-login-divider"></div>

                  <div className="ak-login-btn">
                    {init() === "success" ? (
                      ""
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            this.setState({ showLogin: true, SideNavBar: false });
                          }}
                        >
                          Login Now
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {this.state.secondScreen ? (
              <div>
                {this.state.showVideoLectures ? (
                  <div>
                    {this.state.streams.map((cat) => (
                      <div
                        className="secondDivNavbar"
                        onClick={() => {
                          this.setState({
                            SideNavBar: false,
                          });
                        }}
                      >
                        {this.state.headerType === "facetoface" ? (
                          <Link
                            style={{ textDecoretion: "none" }}
                            to={
                              "/facetoface_details/" +
                              this.state.categoryId +
                              "/" +
                              this.state.levels[0].id +
                              "/" +
                              cat.id
                            }
                          >
                            <div style={{ cursor: "pointer" }}>
                              <p style={{ color: "#000" }}>{cat.name}</p>
                            </div>
                          </Link>
                        ) : this.state.headerType === "course" ? (
                          <Link
                            to={"/subjects/" + cat.id}
                            style={{ textDecoretion: "none" }}
                          >
                            <div style={{ cursor: "pointer" }}>
                              <p style={{ color: "#000" }}>{cat.name}</p>
                            </div>
                          </Link>
                        ) : this.state.headerType === "books" ? (
                          <Link to={"/booksnew?" + "categoryId=" + btoa(this.state.category_id) + "&levelId=" + btoa(this.state.level_id) + "&streamId=" + btoa(this.state.stream_id) + "&subject_filter_id="}>
                            <div style={{ cursor: "pointer" }}>
                              <p style={{ color: "#000" }}>{cat.name}</p>
                            </div>
                          </Link>
                        ) : (
                          <Link
                            to={"/subjects/" + cat.id}
                            style={{ textDecoretion: "none" }}
                          >
                            <div style={{ cursor: "pointer" }}>
                              <p style={{ color: "#000" }}>{cat.name}</p>
                            </div>
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
